<script setup lang="ts">
import type { ButtonSize, ButtonVariant, ButtonColor } from '#ui/types'

const props = withDefaults(
  defineProps<{
    hideTooltip?: boolean
    useFlexAuto?: boolean
    customClass?: string
    labelClass?: string
    customStyle?: { [key: string]: string } | string
    roundButton?: boolean
    buttonDisabled?: boolean
    buttonPadding?: boolean
    buttonBlock?: boolean
    buttonTruncate?: boolean
    buttonLoading?: boolean
    buttonType?: 'button' | 'submit' | 'reset'
    buttonColor?: ButtonColor
    buttonSize?: ButtonSize | undefined
    buttonVariant?: ButtonVariant | undefined
    buttonConfirmColor?: string
    useLeading?: boolean
    useTrailing?: boolean
    iconLeadName?: string
    iconLeadClass?: string
    iconTrailName?: string
    iconTrailClass?: string
    imageUrl?: string
    imageSize?: number
    imageClass?: string
    imageTrailUrl?: string
    imageTrailSize?: number
    imageTrailClass?: string
    buttonText?: string
    tooltipText?: string
    shortcutsText?: Array<string>
  }>(),
  {
    hideTooltip: false,
    useFlexAuto: false,
    customClass: '',
    customStyle: '',
    labelClass: '',
    roundButton: false,
    buttonDisabled: false,
    buttonPadding: true,
    buttonBlock: false,
    buttonTruncate: false,
    buttonLoading: false,
    buttonColor: 'gray',
    buttonConfirmColor: '',
    buttonSize: 'sm',
    buttonType: 'button',
    buttonVariant: 'solid',
    useLeading: false,
    useTrailing: false,
    iconLeadName: '',
    iconLeadClass: 'w-8 h-8',
    iconTrailName: '',
    iconTrailClass: 'w-8 h-8',
    imageUrl: '',
    imageSize: 30,
    imageClass: '',
    imageTrailUrl: '',
    imageTrailSize: 30,
    imageTrailClass: '',
    buttonText: '',
    tooltipText: '',
    shortcutsText: () => [],
  },
)

defineEmits([
  'mouseenter:button',
  'mouseleave:button',
])

const computedConfirmVariant = computed(() => {
  switch (props.buttonConfirmColor) {
    case 'welcome':
      return 'text-lg font-semibold text-gray01Light bg-wnmPrimary'
    case 'primary':
      return 'text-wnmPrimary dark:bg-wnmSecondary dark:text-gray07Dark'
    case 'submit':
      return 'text-lg font-semibold text-white bg-wnmPrimary ring-wnmPrimary focus:ring-wnmPrimary'
    case 'request-meeting':
      return 'text-white dark:text-white bg-gray05Light dark:bg-gray03Dark'
    case 'disabled':
      return 'text-lg font-semibold text-white bg-gray03Light dark:bg-gray03Dark ring-gray03Light dark:ring-gray03Dark focus:ring-gray03Light dark:focus:ring-gray03Dark'
    case 'green':
      return 'text-wnmPrimary bg-green-50 dark:bg-green-800 ring-green-50 dark:ring-green-800 focus:ring-green-50 dark:focus:ring-green-800'
    case 'red':
      return 'text-white bg-accent02RedLight dark:bg-accent02RedDark ring-accent02RedLight dark:ring-accent02RedDark focus:ring-accent02RedLight dark:focus:ring-accent02RedDark'
    case 'gray':
      return 'text-gray05Light dark:text-gray05Dark bg-gray02Light dark:bg-gray02Dark ring-gray02Light dark:ring-gray02Dark focus:ring-gray02Light dark:focus:ring-gray02Dark'
    case 'gray-text-white':
      return 'text-white dark:text-white bg-gray03Light dark:bg-gray03Dark ring-gray03Light dark:ring-gray03Dark focus:ring-gray03Light dark:focus:ring-gray03Dark'
    case 'warning':
      return 'text-white dark:text-white bg-accent02RedLight ring-accent02RedLight dark:bg-accent02RedDark dark:ring-accent02RedDark'
    case 'white':
      return 'text-gray05Light dark:text-gray05Dark bg-gray01Light dark:bg-gray01Dark'
    case 'integrated-alarm':
      return 'text-gray07Light dark:text-gray01Dark bg-gray01Light dark:bg-gray07Dark hover:text-gray07Light hover:dark:text-gray01Dark hover:bg-gray02Light dark:hover:bg-gray06Dark'
    default:
      return 'text-white dark:text-black bg-gray07Light dark:bg-gray07Dark ring-gray07Light dark:ring-gray07Dark focus:ring-gray07Light dark:focus:ring-gray07Dark'
  }
})
</script>

<template>
  <WMTooltip
    :text="tooltipText ? tooltipText : buttonText"
    :style="customStyle"
    :shortcuts="shortcutsText"
    :ui="{
      wrapper: customClass,
      base: (!tooltipText && !buttonText) || hideTooltip ? 'hidden' : '[@media(pointer:coarse)]:hidden h-6 px-2 py-1 text-xs font-normal truncate relative',
    }"
  >
    <WMButton
      :class="customClass"
      :disabled="buttonDisabled"
      :block="buttonBlock"
      :truncate="buttonTruncate"
      :padded="buttonPadding"
      :color="buttonColor"
      :loading="buttonLoading"
      :size="buttonSize"
      :type="buttonType"
      :variant="buttonVariant"
      :aria-label="buttonText"
      :ui="{
        rounded: roundButton ? 'rounded-full' : 'rounded-md',
        variant: {
          outline: 'text-gray05Light dark:text-gray05Dark ring-gray03Light dark:ring-gray03Dark focus:ring-gray04Light dark:focus:ring-gray04Dark',
          soft: computedConfirmVariant,
        },
      }"
      @mouseenter="$emit('mouseenter:button')"
      @mouseleave="$emit('mouseleave:button')"
    >
      <span
        v-if="buttonText"
        :class="labelClass"
      >
        {{ buttonText }}
      </span>
      <div
        v-if="useLeading && useTrailing && useFlexAuto"
        class="flex-auto"
      />
      <template
        v-if="useLeading"
        #leading
      >
        <Icon
          v-if="iconLeadName"
          :name="iconLeadName"
          :class="iconLeadClass"
        />
        <NuxtImg
          v-if="imageUrl"
          :class="imageClass"
          :src="imageUrl"
          :width="imageSize"
        />
      </template>
      <template
        v-if="useTrailing"
        #trailing
      >
        <Icon
          v-if="iconTrailName"
          :class="iconTrailClass"
          :name="iconTrailName"
        />
        <NuxtImg
          v-if="imageTrailUrl"
          :class="imageTrailClass"
          :src="imageTrailUrl"
          :width="imageTrailSize"
        />
      </template>
    </WMButton>
  </WMTooltip>
</template>
